import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class Video extends Component {
    render () {
        return (
            <div className='player-wrapper'>
                <ReactPlayer
                    className='react-player fixed-bottom'
                    url='Animatie-SWA-55-sec-1.mp4'
                    width='100%'
                    height='100%'
                    controls = {true}

                />
            </div>
        )
    }
}

export default Video;
