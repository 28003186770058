/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "sw-admin-swprod",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://djdz9rse0m9z1.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-central-1:692e7169-4755-4cd0-99a2-5c7cdcb68d0a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_5b42GXsnm",
    "aws_user_pools_web_client_id": "4tev2g50qksl97d7rbvn85asud",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://cernvqq5jvf5jfvx3gmbd3utqu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "11f9a7f5e5e74ca5a36b0dc48e56d6ad",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "slimwonen-dynamic-content170904-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
