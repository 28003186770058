/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const verbruikData = /* GraphQL */ `
  query VerbruikData($input: InputVerbruikData) {
    verbruikData(input: $input)
  }
`;
export const uitdagingenData = /* GraphQL */ `
  query UitdagingenData($input: InputUitdagingenData) {
    uitdagingenData(input: $input)
  }
`;
export const compareData = /* GraphQL */ `
  query CompareData($input: InputVerbruikData) {
    compareData(input: $input)
  }
`;
export const getMaatregel = /* GraphQL */ `
  query GetMaatregel($id: ID!) {
    getMaatregel(id: $id) {
      id
      email
      name
      category
      address {
        streetName
        houseNumber
        houseNumberAdditive
        province
        zipcode
        city
      }
      houseData {
        type
        privateOwner
        vve
        buildYear
        squareMeters
        nrOfResidents
        protectedStatus
      }
      dateOfCreation
      questions {
        question
        answer
      }
      createdAt
      updatedAt
      supplierIDs
      owner
    }
  }
`;
export const listMaatregels = /* GraphQL */ `
  query ListMaatregels(
    $filter: ModelMaatregelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaatregels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        name
        category
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        houseData {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        dateOfCreation
        questions {
          question
          answer
        }
        createdAt
        updatedAt
        supplierIDs
        owner
      }
      nextToken
    }
  }
`;
export const getTipsEnKlusjes = /* GraphQL */ `
  query GetTipsEnKlusjes($id: ID!) {
    getTipsEnKlusjes(id: $id) {
      id
      title
      showStatus
      icon
      description
      value
      categoryIndex
      createdAt
      updatedAt
    }
  }
`;
export const listTipsEnKlusjess = /* GraphQL */ `
  query ListTipsEnKlusjess(
    $id: ID
    $filter: ModelTipsEnKlusjesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTipsEnKlusjess(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        showStatus
        icon
        description
        value
        categoryIndex
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIsoleerEnMeer = /* GraphQL */ `
  query GetIsoleerEnMeer($id: ID!) {
    getIsoleerEnMeer(id: $id) {
      id
      title
      categoryIndex
      showStatus
      typeHouse
      description
      value
      link
      icon
      cevText
      cevScore
      ueoText
      ueoScore
      cost
      saving
      time
      createdAt
      updatedAt
    }
  }
`;
export const listIsoleerEnMeers = /* GraphQL */ `
  query ListIsoleerEnMeers(
    $id: ID
    $filter: ModelIsoleerEnMeerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIsoleerEnMeers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        categoryIndex
        showStatus
        typeHouse
        description
        value
        link
        icon
        cevText
        cevScore
        ueoText
        ueoScore
        cost
        saving
        time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApparaten = /* GraphQL */ `
  query GetApparaten($id: ID!) {
    getApparaten(id: $id) {
      id
      title
      categoryIndex
      icon
      showStatus
      description
      value
      top5 {
        items {
          id
          title
          description
          value
          label
          link
          ApparatenID
          usage
          brand
          imageLink
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listApparatens = /* GraphQL */ `
  query ListApparatens(
    $id: ID
    $filter: ModelApparatenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApparatens(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        categoryIndex
        icon
        showStatus
        description
        value
        top5 {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApparaat = /* GraphQL */ `
  query GetApparaat($id: ID!) {
    getApparaat(id: $id) {
      id
      title
      description
      value
      label
      link
      ApparatenID
      usage
      brand
      imageLink
      createdAt
      updatedAt
    }
  }
`;
export const listApparaats = /* GraphQL */ `
  query ListApparaats(
    $id: ID
    $filter: ModelApparaatFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApparaats(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        value
        label
        link
        ApparatenID
        usage
        brand
        imageLink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      userEmail
      message
      date
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userEmail
        message
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getP4Day = /* GraphQL */ `
  query GetP4Day($houseID: ID!, $date: AWSDate!) {
    getP4Day(houseID: $houseID, date: $date) {
      activationKey
      houseID
      date
      status
      gasTries
      gasFailCode
      electricityTries
      electricityFailCode
      electricityTarif
      gasTarif
      backfeedTarif
      gasProvider {
        ean
        name
      }
      gasEAN
      gasMeasurement {
        meter
        unit
      }
      electricityProvider {
        ean
        name
      }
      electricityEAN
      electricityMeasurement {
        meter
        unit
      }
      backfeedMeasurement {
        meter
        unit
      }
      clientID
      houseType
      createdAt
      updatedAt
      house {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listP4Days = /* GraphQL */ `
  query ListP4Days(
    $houseID: ID
    $date: ModelStringKeyConditionInput
    $filter: ModelP4DayFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listP4Days(
      houseID: $houseID
      date: $date
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activationKey
        houseID
        date
        status
        gasTries
        gasFailCode
        electricityTries
        electricityFailCode
        electricityTarif
        gasTarif
        backfeedTarif
        gasProvider {
          ean
          name
        }
        gasEAN
        gasMeasurement {
          meter
          unit
        }
        electricityProvider {
          ean
          name
        }
        electricityEAN
        electricityMeasurement {
          meter
          unit
        }
        backfeedMeasurement {
          meter
          unit
        }
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getExpoToken = /* GraphQL */ `
  query GetExpoToken($token: String!) {
    getExpoToken(token: $token) {
      token
      houseID
      activationCode
      createdAt
      updatedAt
      house {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listExpoTokens = /* GraphQL */ `
  query ListExpoTokens(
    $token: String
    $filter: ModelExpoTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExpoTokens(
      token: $token
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        token
        houseID
        activationCode
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessageCampaign = /* GraphQL */ `
  query GetMessageCampaign($id: ID!) {
    getMessageCampaign(id: $id) {
      id
      sendDate
      title
      message
      icon
      targetClientID
      pushNotification
      status
      createdAt
      updatedAt
      targetClient {
        id
        name
        createdAt
        updatedAt
        houses {
          nextToken
        }
      }
    }
  }
`;
export const listMessageCampaigns = /* GraphQL */ `
  query ListMessageCampaigns(
    $id: ID
    $filter: ModelMessageCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessageCampaigns(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sendDate
        title
        message
        icon
        targetClientID
        pushNotification
        status
        createdAt
        updatedAt
        targetClient {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoningMessage = /* GraphQL */ `
  query GetWoningMessage($id: ID!) {
    getWoningMessage(id: $id) {
      id
      houseID
      activationCode
      sendDate
      readDate
      title
      message
      status
      pushNotification
      campaignID
      campaign {
        id
        sendDate
        title
        message
        icon
        targetClientID
        pushNotification
        status
        createdAt
        updatedAt
        targetClient {
          id
          name
          createdAt
          updatedAt
        }
      }
      createdAt
      updatedAt
      house {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listWoningMessages = /* GraphQL */ `
  query ListWoningMessages(
    $id: ID
    $filter: ModelWoningMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWoningMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        houseID
        activationCode
        sendDate
        readDate
        title
        message
        status
        pushNotification
        campaignID
        campaign {
          id
          sendDate
          title
          message
          icon
          targetClientID
          pushNotification
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAanvraag = /* GraphQL */ `
  query GetAanvraag($id: ID!) {
    getAanvraag(id: $id) {
      id
      email
      description
      activationCode
      dateOfCreation
      zipcode
      city
      streetName
      houseNumber
      houseNumberAddition
      groupID
      clientID
      createdAt
      updatedAt
      group {
        id
        name
        description
        clientID
        createdAt
        updatedAt
        houses {
          nextToken
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      client {
        id
        name
        createdAt
        updatedAt
        houses {
          nextToken
        }
      }
    }
  }
`;
export const listAanvraags = /* GraphQL */ `
  query ListAanvraags(
    $filter: ModelAanvraagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAanvraags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        description
        activationCode
        dateOfCreation
        zipcode
        city
        streetName
        houseNumber
        houseNumberAddition
        groupID
        clientID
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getApparaatByApparaten = /* GraphQL */ `
  query GetApparaatByApparaten(
    $ApparatenID: ID
    $value: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApparaatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getApparaatByApparaten(
      ApparatenID: $ApparatenID
      value: $value
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        value
        label
        link
        ApparatenID
        usage
        brand
        imageLink
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getP4DayByActivation = /* GraphQL */ `
  query GetP4DayByActivation(
    $activationKey: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelP4DayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getP4DayByActivation(
      activationKey: $activationKey
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activationKey
        houseID
        date
        status
        gasTries
        gasFailCode
        electricityTries
        electricityFailCode
        electricityTarif
        gasTarif
        backfeedTarif
        gasProvider {
          ean
          name
        }
        gasEAN
        gasMeasurement {
          meter
          unit
        }
        electricityProvider {
          ean
          name
        }
        electricityEAN
        electricityMeasurement {
          meter
          unit
        }
        backfeedMeasurement {
          meter
          unit
        }
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getP4DayByStatus = /* GraphQL */ `
  query GetP4DayByStatus(
    $status: P4Status
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelP4DayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getP4DayByStatus(
      status: $status
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activationKey
        houseID
        date
        status
        gasTries
        gasFailCode
        electricityTries
        electricityFailCode
        electricityTarif
        gasTarif
        backfeedTarif
        gasProvider {
          ean
          name
        }
        gasEAN
        gasMeasurement {
          meter
          unit
        }
        electricityProvider {
          ean
          name
        }
        electricityEAN
        electricityMeasurement {
          meter
          unit
        }
        backfeedMeasurement {
          meter
          unit
        }
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getExpoTokenByWoningActivation = /* GraphQL */ `
  query GetExpoTokenByWoningActivation(
    $activationCode: String
    $houseID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExpoTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getExpoTokenByWoningActivation(
      activationCode: $activationCode
      houseID: $houseID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        token
        houseID
        activationCode
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMessageCampaignByCLient = /* GraphQL */ `
  query GetMessageCampaignByCLient(
    $targetClientID: ID
    $sendDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageCampaignByCLient(
      targetClientID: $targetClientID
      sendDate: $sendDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sendDate
        title
        message
        icon
        targetClientID
        pushNotification
        status
        createdAt
        updatedAt
        targetClient {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoningMessageSorted = /* GraphQL */ `
  query GetWoningMessageSorted(
    $activationCode: String
    $houseIDSendDate: ModelWoningMessageSortedByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWoningMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWoningMessageSorted(
      activationCode: $activationCode
      houseIDSendDate: $houseIDSendDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        houseID
        activationCode
        sendDate
        readDate
        title
        message
        status
        pushNotification
        campaignID
        campaign {
          id
          sendDate
          title
          message
          icon
          targetClientID
          pushNotification
          status
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listWonings = /* GraphQL */ `
  query ListWonings(
    $id: ID
    $filter: ModelWoningFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWonings(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoning = /* GraphQL */ `
  query GetWoning($id: ID!) {
    getWoning(id: $id) {
      id
      currentResident
      currentResidentEmail
      currentResidentName
      requiredData
      bespaarScore
      bespaarDoel
      dateOfCreation
      dateOfActivation
      contractDuration
      activationCode
      address {
        streetName
        houseNumber
        houseNumberAdditive
        province
        zipcode
        city
      }
      accountStatus
      gasProvider {
        ean
        name
      }
      gasEAN
      electricityProvider {
        ean
        name
      }
      electricityEAN
      clientID
      groupID
      woningSpecificaties {
        type
        privateOwner
        vve
        buildYear
        squareMeters
        nrOfResidents
        protectedStatus
      }
      woningEnergieContract {
        currentSupplier
        tarifElectricity
        gasUsage
        tarifGas
        solarPanelsUsage
        tarifSolarPanels
        monthlyDeposit
        startDateContract
        fixedChargeGas
        fixedCharge
        greenElectricity
        energyContractChange
        energyContractChangeDate
        energyContractType
      }
      lastUpdateDate
      tipsEnKlusjes {
        status
        title
        description
        value
        icon
        id
        categoryIndex
      }
      apparaten {
        status
        title
        description
        value
        icon
        id
        categoryIndex
      }
      isoleerEnMeer {
        categoryIndex
        title
        description
        status
        icon
        id
        value
      }
      energieOverstap {
        status
        value
      }
      besparenTop3
      avatar
      maatregelRequests {
        category
        status
      }
      addressKey
      createdAt
      updatedAt
      group {
        id
        name
        description
        clientID
        createdAt
        updatedAt
        houses {
          nextToken
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      client {
        id
        name
        createdAt
        updatedAt
        houses {
          nextToken
        }
      }
    }
  }
`;
export const getWoningByActivation = /* GraphQL */ `
  query GetWoningByActivation(
    $activationCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelWoningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWoningByActivation(
      activationCode: $activationCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoningByClient = /* GraphQL */ `
  query GetWoningByClient(
    $clientID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWoningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWoningByClient(
      clientID: $clientID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoningByGroup = /* GraphQL */ `
  query GetWoningByGroup(
    $groupID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWoningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWoningByGroup(
      groupID: $groupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWoningbyAddressKey = /* GraphQL */ `
  query GetWoningbyAddressKey(
    $addressKey: String
    $sortDirection: ModelSortDirection
    $filter: ModelWoningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getWoningbyAddressKey(
      addressKey: $addressKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMaatregelSupplier = /* GraphQL */ `
  query GetMaatregelSupplier($id: ID!) {
    getMaatregelSupplier(id: $id) {
      id
      email
      name
      supportedCategories
      createdAt
      updatedAt
      logoUrl
    }
  }
`;
export const listMaatregelSuppliers = /* GraphQL */ `
  query ListMaatregelSuppliers(
    $id: ID
    $filter: ModelMaatregelSupplierFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMaatregelSuppliers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        email
        name
        supportedCategories
        createdAt
        updatedAt
        logoUrl
      }
      nextToken
    }
  }
`;
export const listHouseGroups = /* GraphQL */ `
  query ListHouseGroups(
    $id: ID
    $filter: ModelHouseGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listHouseGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        clientID
        createdAt
        updatedAt
        houses {
          nextToken
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getHouseGroup = /* GraphQL */ `
  query GetHouseGroup($id: ID!) {
    getHouseGroup(id: $id) {
      id
      name
      description
      clientID
      createdAt
      updatedAt
      houses {
        items {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
        nextToken
      }
      client {
        id
        name
        createdAt
        updatedAt
        houses {
          nextToken
        }
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $id: ID
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClients(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        houses {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      name
      createdAt
      updatedAt
      houses {
        items {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const getP4Aggregation = /* GraphQL */ `
  query GetP4Aggregation(
    $houseID: ID!
    $aggregationType: P4AggregationType!
    $date: AWSDate!
  ) {
    getP4Aggregation(
      houseID: $houseID
      aggregationType: $aggregationType
      date: $date
    ) {
      activationKey
      houseID
      aggregationType
      date
      startDateGas
      endDateGas
      startDateElec
      endDateElec
      startDateBack
      endDateBack
      gasUsage
      electricityUsage
      backfeedUsage
      gasTarif
      electricityTarif
      backfeedTarif
      gasStart
      electricityStart
      backfeedStart
      gasEnd
      electricityEnd
      backfeedEnd
      clientID
      houseType
      createdAt
      updatedAt
      house {
        id
        currentResident
        currentResidentEmail
        currentResidentName
        requiredData
        bespaarScore
        bespaarDoel
        dateOfCreation
        dateOfActivation
        contractDuration
        activationCode
        address {
          streetName
          houseNumber
          houseNumberAdditive
          province
          zipcode
          city
        }
        accountStatus
        gasProvider {
          ean
          name
        }
        gasEAN
        electricityProvider {
          ean
          name
        }
        electricityEAN
        clientID
        groupID
        woningSpecificaties {
          type
          privateOwner
          vve
          buildYear
          squareMeters
          nrOfResidents
          protectedStatus
        }
        woningEnergieContract {
          currentSupplier
          tarifElectricity
          gasUsage
          tarifGas
          solarPanelsUsage
          tarifSolarPanels
          monthlyDeposit
          startDateContract
          fixedChargeGas
          fixedCharge
          greenElectricity
          energyContractChange
          energyContractChangeDate
          energyContractType
        }
        lastUpdateDate
        tipsEnKlusjes {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        apparaten {
          status
          title
          description
          value
          icon
          id
          categoryIndex
        }
        isoleerEnMeer {
          categoryIndex
          title
          description
          status
          icon
          id
          value
        }
        energieOverstap {
          status
          value
        }
        besparenTop3
        avatar
        maatregelRequests {
          category
          status
        }
        addressKey
        createdAt
        updatedAt
        group {
          id
          name
          description
          clientID
          createdAt
          updatedAt
        }
        client {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listP4Aggregations = /* GraphQL */ `
  query ListP4Aggregations(
    $houseID: ID
    $aggregationTypeDate: ModelP4AggregationPrimaryCompositeKeyConditionInput
    $filter: ModelP4AggregationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listP4Aggregations(
      houseID: $houseID
      aggregationTypeDate: $aggregationTypeDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        activationKey
        houseID
        aggregationType
        date
        startDateGas
        endDateGas
        startDateElec
        endDateElec
        startDateBack
        endDateBack
        gasUsage
        electricityUsage
        backfeedUsage
        gasTarif
        electricityTarif
        backfeedTarif
        gasStart
        electricityStart
        backfeedStart
        gasEnd
        electricityEnd
        backfeedEnd
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getP4AggByActivation = /* GraphQL */ `
  query GetP4AggByActivation(
    $activationKey: String
    $aggregationTypeDate: ModelP4AggregationByActivationCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelP4AggregationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getP4AggByActivation(
      activationKey: $activationKey
      aggregationTypeDate: $aggregationTypeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activationKey
        houseID
        aggregationType
        date
        startDateGas
        endDateGas
        startDateElec
        endDateElec
        startDateBack
        endDateBack
        gasUsage
        electricityUsage
        backfeedUsage
        gasTarif
        electricityTarif
        backfeedTarif
        gasStart
        electricityStart
        backfeedStart
        gasEnd
        electricityEnd
        backfeedEnd
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getP4AggbyClientHousetypeDate = /* GraphQL */ `
  query GetP4AggbyClientHousetypeDate(
    $clientID: ID
    $aggregationTypeHouseTypeDate: ModelP4AggregationByClientHousetypeDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelP4AggregationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getP4AggbyClientHousetypeDate(
      clientID: $clientID
      aggregationTypeHouseTypeDate: $aggregationTypeHouseTypeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activationKey
        houseID
        aggregationType
        date
        startDateGas
        endDateGas
        startDateElec
        endDateElec
        startDateBack
        endDateBack
        gasUsage
        electricityUsage
        backfeedUsage
        gasTarif
        electricityTarif
        backfeedTarif
        gasStart
        electricityStart
        backfeedStart
        gasEnd
        electricityEnd
        backfeedEnd
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getP4AggbyDateHousetype = /* GraphQL */ `
  query GetP4AggbyDateHousetype(
    $houseType: HouseType
    $aggregationTypeDate: ModelP4AggregationByDateHousetypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelP4AggregationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getP4AggbyDateHousetype(
      houseType: $houseType
      aggregationTypeDate: $aggregationTypeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activationKey
        houseID
        aggregationType
        date
        startDateGas
        endDateGas
        startDateElec
        endDateElec
        startDateBack
        endDateBack
        gasUsage
        electricityUsage
        backfeedUsage
        gasTarif
        electricityTarif
        backfeedTarif
        gasStart
        electricityStart
        backfeedStart
        gasEnd
        electricityEnd
        backfeedEnd
        clientID
        houseType
        createdAt
        updatedAt
        house {
          id
          currentResident
          currentResidentEmail
          currentResidentName
          requiredData
          bespaarScore
          bespaarDoel
          dateOfCreation
          dateOfActivation
          contractDuration
          activationCode
          accountStatus
          gasEAN
          electricityEAN
          clientID
          groupID
          lastUpdateDate
          besparenTop3
          avatar
          addressKey
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUsageAggregations = /* GraphQL */ `
  query GetUsageAggregations(
    $houseType: String!
    $energyType: EnergyType!
    $date: AWSDate!
  ) {
    getUsageAggregations(
      houseType: $houseType
      energyType: $energyType
      date: $date
    ) {
      date
      houseType
      energyType
      tenAvg
      twentyAvg
      thirtyAvg
      fourtyAvg
      fiftyAvg
      sixtyAvg
      seventyAvg
      eightyAvg
      ninetyAvg
      hundredAvg
      best
      worst
      createdAt
      updatedAt
    }
  }
`;
export const listUsageAggregationss = /* GraphQL */ `
  query ListUsageAggregationss(
    $houseType: String
    $energyTypeDate: ModelUsageAggregationsPrimaryCompositeKeyConditionInput
    $filter: ModelUsageAggregationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsageAggregationss(
      houseType: $houseType
      energyTypeDate: $energyTypeDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        date
        houseType
        energyType
        tenAvg
        twentyAvg
        thirtyAvg
        fourtyAvg
        fiftyAvg
        sixtyAvg
        seventyAvg
        eightyAvg
        ninetyAvg
        hundredAvg
        best
        worst
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsageAggbyHousetypeEnergytypeDate = /* GraphQL */ `
  query GetUsageAggbyHousetypeEnergytypeDate(
    $houseType: String
    $energyTypeDate: ModelUsageAggregationsByHousetypeEnergytypeDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsageAggregationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsageAggbyHousetypeEnergytypeDate(
      houseType: $houseType
      energyTypeDate: $energyTypeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        houseType
        energyType
        tenAvg
        twentyAvg
        thirtyAvg
        fourtyAvg
        fiftyAvg
        sixtyAvg
        seventyAvg
        eightyAvg
        ninetyAvg
        hundredAvg
        best
        worst
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsageAggbyHousetypeDate = /* GraphQL */ `
  query GetUsageAggbyHousetypeDate(
    $houseType: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUsageAggregationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsageAggbyHousetypeDate(
      houseType: $houseType
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        date
        houseType
        energyType
        tenAvg
        twentyAvg
        thirtyAvg
        fourtyAvg
        fiftyAvg
        sixtyAvg
        seventyAvg
        eightyAvg
        ninetyAvg
        hundredAvg
        best
        worst
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const addressExists = /* GraphQL */ `
  query AddressExists(
    $city: String!
    $zipcode: String!
    $houseNumber: Int!
    $additive: String
    $email: AWSEmail!
  ) {
    addressExists(
      city: $city
      zipcode: $zipcode
      houseNumber: $houseNumber
      additive: $additive
      email: $email
    )
  }
`;
