// @ts-nocheck
import React from 'react';
import {Formik, Form, Field} from 'formik';
import {Button, LinearProgress} from '@material-ui/core';
import {TextField} from 'formik-material-ui';
import 'react-app-polyfill/stable';

import Amplify, {API} from 'aws-amplify';
import awsmobile from './aws-exports';
import {addressExists} from "./graphql/queries";
import * as yup from 'yup';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {messages} from './messages';
import './App.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import Video from './Video'

// Amplify intital configuration
Amplify.configure(awsmobile);

// Amplify configure API
Amplify.configure({
    API: {
        graphql_headers: async () => {
            try {
                const session = await Amplify.Auth.currentSession();
                return ({
                    Authorization: session.getIdToken().getJwtToken(),
                });
            } catch (e) {
                console.log(e);
                return;
            }

        },
    },
});

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 700,
        color: '#3f6667',
    },
    body: {
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 400,
        color: '#575757',
    },
    link: {
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 400,
        color: '#3f6667',
        textDecoration: 'underline',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        fontFamily: 'Titillium Web, sans-serif',
        fontWeight: 400,
        color: '#575757',
    },
    submit: {
        alignSelf: 'center',
        width: '100%',
        fontFamily: 'Titillium Web, sans-serif',
        color: 'white',
        fontWeight: 700,
        backgroundColor: 'rgb(231, 131, 69)',
        borderRadius: 20,
        borderColor: 'rgb(231, 131, 69)',
        '&:hover': {
            backgroundColor: 'white',
            borderColor: 'white',
            color: '#3f6667',
            boxShadow: 'none',
        },
    },
});

export interface Props {
    currentEmailUpdate?: any;
    currentPasswordUpdate?: any;
}

export interface State {
    isLoading: boolean;
    streetName: string;
    houseNumber: string;
    houseNumberAdditive: string;
    error: string | null;
    activationCode: string;
}

class App extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
            streetName: '',
            houseNumber: '',
            houseNumberAdditive: '',
            error: '',
            activationCode: '',
        };
    }


    async getAddress(zipCode: string, houseNumber: string, houseNumberAddition: string, email: string,) {
        this.setState({isLoading: true});
        const proxyUrl = "https://cors-anywhere.herokuapp.com/";
        const url = `${proxyUrl}https://api.postcodeapi.nu/v3/lookup/${zipCode}/${houseNumber}`;
        try {
            let response = await fetch(url, {
                method: 'GET',
                headers: {
                    "X-Api-Key": "RvfCHfdqwV6rFJAYmEKnuaMOiWttBEz72y79U30Q"
                }
            });
            console.log('response', response)

            let data: any = await response.json();
            console.log('data', data)
            if (data.title) {
                this.setState({
                    error: data.title,
                    isLoading: false,
                });
                return false
            } else {
                this.setState({
                    streetName: data.street,
                    error: null,
                });
                // @ts-ignore
                const aE = await API.graphql({
                    query: addressExists,
                    variables: {
                        city: data.city,
                        zipcode: zipCode,
                        houseNumber,
                        ...(houseNumberAddition && houseNumberAddition !== '' && {additive: houseNumberAddition}),
                        email: email,
                    },
                    // @ts-ignore
                    authMode: 'AWS_IAM',
                });
                const address = JSON.parse(aE.data.addressExists);
                // Address in admin
                //TODO POPUPS
                this.setState({
                    isLoading: false,
                });
                return address;
            }
        } catch (e) {
            console.log('error', {e});
            this.setState({
                isLoading: false,
            });
            return null;
        }
    }


    async handleSubmitCheck(values: any, setSubmitting: any) {
        try {
            console.log('handleSubmit')
            const res = await this.getAddress(values.zipCode, values.houseNumber, values.houseNumberAdditive, values.email);
            console.log('res', res)
            if (res) {
                console.log('res',res)
                // get activation code
                if (res.exists) {
                    if (res.active === 'Active') {
                        toast.error(messages.alreadyActivated);
                        setSubmitting(false);
                    } else {
                        toast.success(messages.signedupSuccessfully);
                        setSubmitting(false);
                    }
                } else {
                    toast.error(messages.notAvailable);
                    setSubmitting(false);
                }
            } else {
                if (this.state.error) {
                    //  Addres niet geldig
                    toast.error(messages.incorrectAddress);
                    setSubmitting(false);
                } else {
                    //  Niet in de slimwonen database
                    toast.error(messages.notAvailable);
                    setSubmitting(false);
                }
            }
        } catch (e) {
            console.log('handle error', e)
            toast.error(messages.somethingWentWrong);
            setSubmitting(false);
        }
    }


    render() {
        const {isLoading, streetName, error} = this.state;
        const {classes} = this.props;

        return (
            <Container component="main" maxWidth="md">
                <ToastContainer/>
                <CssBaseline/>
                <Grid container spacing={6} direction="row"
                      justify="center"
                      alignItems="center">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className={classes.paper}>
                            <Typography className={classes.header} component="h1" variant="h5">
                                Activatiecode aanvragen
                            </Typography>
                            <br/>
                            <Typography className={classes.body} component="p" variant="body1">
                                Vul hier jouw gegevens in om een activatiecode aan te vragen. Deze wordt per post
                                verstuurd.
                            </Typography>
                            <Formik
                                initialValues={{
                                    email: '',
                                    zipCode: '',
                                    houseNumber: '',
                                    streetName,
                                    houseNumberAdditive: '',
                                }}
                                validationSchema={
                                    yup.object().shape({
                                        email: yup
                                            .string()
                                            .email('Foutieve email')
                                            .required('Email adres is verplicht'),
                                        zipCode: yup
                                            .string()
                                            .required('Postcode is verplicht')
                                            .matches(
                                                /^[0-9]{4}[a-zA-Z]{2}$/,
                                                'De postcode bestaat uit 4 cijfers gevolgd door 2 letters',
                                            ),
                                        houseNumber: yup.string()
                                            .typeError('Een huisnummer bestaat alleen uit getallen.')
                                            .required('Huisnummer is verplicht')
                                            .matches(
                                                /^[0-9]+$/,
                                                'Een huisnummer bestaat alleen uit getallen.'
                                            )
                                    })
                                }
                                onSubmit={(values, {setSubmitting}) => {
                                    this.handleSubmitCheck(values, setSubmitting);
                                }}
                            >
                                {({
                                      submitForm, isSubmitting, errors
                                  }) => (
                                    <Form className={classes.form}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Field
                                                    component={TextField}
                                                    autoFocus
                                                    variant="outlined"
                                                    required
                                                    disabled={isLoading}
                                                    fullWidth
                                                    name="zipCode"
                                                    label="Postcode"
                                                    id="zipCode"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    component={TextField}
                                                    name="houseNumber"
                                                    variant="outlined"
                                                    required
                                                    disabled={isLoading}
                                                    fullWidth
                                                    id="houseNumber"
                                                    label="Huisnummer"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    disabled={isLoading}
                                                    fullWidth
                                                    id="houseNumberAdditive"
                                                    label="Toevoeging"
                                                    name="houseNumberAdditive"
                                                />
                                            </Grid>
                                            {error &&
                                            <Grid item xs={12}>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    name="streetName"
                                                    label={streetName}
                                                    id="streetName"
                                                    disabled
                                                />
                                            </Grid>}
                                            <Grid item xs={12}>
                                                <Field
                                                    component={TextField}
                                                    variant="outlined"
                                                    required
                                                    fullWidth
                                                    disabled={isLoading}
                                                    id="email"
                                                    label="E-mailadres"
                                                    name="email"
                                                    autoComplete="email"
                                                />
                                            </Grid>
                                        </Grid>
                                        {isSubmitting && <LinearProgress/>}
                                        <br/>
                                        <Button
                                            className={classes.submit}
                                            variant="contained"
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                        >
                                            Bevestigen
                                        </Button>

                                    </Form>
                                )}
                            </Formik>
                            <Box mt={4}>
                                <Typography className={classes.body} variant="body2" color="textSecondary"
                                            align="center">
                                    {'Door op bevestigen te klikken, ga je akkoord met onze '}
                                    <Link className={classes.link} color="inherit"
                                          href="https://slimwonenapp.nl/privacy-statement/">
                                        Privacy statement
                                    </Link>{' en '}
                                    <Link className={classes.link} color="inherit"
                                          href="https://slimwonenapp.nl/algemene-voorwaarden/">
                                        Algemene voorwaarden
                                    </Link>
                                    {'.'}
                                </Typography>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Typography className={classes.header} component="h1" variant="h5">
                            Meer weten over de SlimWonen App? Bekijk dan dit filmpje!
                        </Typography>
                        <Video />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles)(App);
